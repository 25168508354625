@mixin centering-elements($horizontal: true, $vertical: true) {
  position: absolute;
  @if $horizontal and $vertical {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if $horizontal {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if $vertical {
    top: 50%;
    transform: translate(0, -50%);
  }
}


// mediaquery
$breakpoints: (
  'ssm': 521,
  'sm': 576,
  'md': 768,
  'lg': 992,
  'xl': 1200,
) !default;

@mixin mq($mq, $bp1: md, $bp2: lg) {

  $w1    : map-get($breakpoints, $bp1);
  $w2    : map-get($breakpoints, $bp2);
  $min1  : 'min-width: #{convert-em($w1, 1)}';
  $min2  : 'min-width: #{convert-em($w1, 1)}';
  $max1  : 'max-width: #{convert-em($w1)}';
  $max2  : 'max-width: #{convert-em($w2)}';

  @if $mq == min {
    @media screen and ($min1) {
      @content;
    }
  }
  @else if $mq == max {
    @media screen and ($max1) {
      @content;
    }
  }
  @else if $mq == min-max {
    @media screen and ($min2) and ($max2) {
      @content;
    }
  }
}

@function convert-em($width, $ge: 0) {
  @return '#{($width + $ge) / 16}em';
}
