@import "mixin";

$bg-color: #242424;
$recruit-brand-color: #DF250F;


body {
  background:$bg-color;
}
a{
  color: #dbdbdb;
  &:hover{
    color: #ccc;
  }
}

.navbar{
  background: #fff;
  padding: 0;
  .navbar-brand{
    height: 76px;
    img{
      width:184px;
      padding: 10px 0 0;
    }
  }
  @include mq('max', 'xl') {
    justify-content: center;
  }
  @include mq('min', 'xl') {
    padding-left: 15px;
    padding-right:15px;
  }
  .navbar-toggler{
    padding: 0;
    border-radius: 0;
    width:19px;
    height:22px;
    top: 30px;
    left:33px;
    position:absolute;
    // position: relative;
    cursor:pointer;
    overflow:hidden;
    z-index:9999;
    .menu1{ width:19px;
      height:3px;
      background-color: #000;
      top:3px;
      position:absolute;
    }
    .menu2{ width:19px;
      height:3px;
      background-color: #000;
      top:10px;
      position:absolute;
      transition: all 0.1s ease-in-out;
    }
    .menu3{ width:19px;
      height:3px;
      background-color: #000;
      top:17px;
      position:absolute;
    }
    .menu1,.menu3{
      transition: all 0.5s ease-out;
    }
    .menuclick1{ top:8px;
      -o-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);	-ms-transform: rotate(135deg);
      transform: rotate(135deg);
    }
    .menuclick2{ background-color:rgba(255,255,255,0);
     }
    .menuclick3{ top:8px;
      -o-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);-webkit-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }
  #navbars{
    ul{
      li{
        a{
          color: #575757;
        }
      }
      li#nav-sns{
        a{
          font-weight: normal;
        }
      }
      li#nav-recruit{
        background: $recruit-brand-color;
        font-size: .9rem;
        font-weight: bold;
        margin-left: 1rem;
        // margin-top: .21rem;
        a{
          color: #fff;
        }
      }
      @include mq('max', 'xl') {
        display: flex;
        flex-wrap:wrap;
        flex-direction: row;
        background: #F5F6F5;
        li{
          width: 25%;
          text-align: center;
          border-bottom: 1px solid #EBEBEB;
          font-size: 1.2rem;
          &+li{
            border-left: 1px solid #EBEBEB;
          }
          &:nth-child(4n+1){
            border-left: 0;
          }
          a{
            padding:0 .2rem;
            display: block;
            font-weight: bold;
            span{
              border-bottom:3px solid transparent;
              padding:.7rem 0;
              margin-bottom:2px;
              display: inline-block;
            }
          }
          &#nav-sns{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.6rem;
            a{
              margin:0 .3rem;
              font-weight: normal;
            }
          }
          &#nav-recruit{
            // width: 50%;
            margin-left: 0;
            font-size: 1.2rem;
          }
        }
      }
      @media (max-width:600px) and (min-width:520px) {
        li{
          font-size: 1rem;
          &#nav-sns{
            font-size: 1.2rem;
            a{
              margin:0 .2rem;
              font-weight: normal;
            }
          }
        }
      }
      @include mq('max', 'ssm') {
        li{
          width: 50%;
          &:hover{
            background: #eee;
          }
          &#nav-sns{
            font-size: 1.8rem;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            a{
              margin:.5rem .4rem;
              font-weight: normal;
            }
          }
          &#nav-recruit{
            width: 100%;
          }
        }
      }
      @include mq('min', 'xl') {
        width: 100%;
        justify-content:flex-end;
        li{
          a{
            padding: .5em .8rem;
          }
          &#nav-sns{
            display: flex;
            font-size: 1.8rem;
            margin:0 .8rem;
            line-height: 1.4;
            a{
              padding: 0 .5rem;
            }
          }
          &#nav-recruit{
            a{
              padding: .5rem .5rem .2rem;
            }
          }
        }
      }
    }
  }
}
.top #navbars ul li#nav-top,
.news #navbars ul li#nav-news,
.menu #navbars ul li#nav-menu,
.locations #navbars ul li#nav-locations,
.about #navbars ul li#nav-about,
.contact #navbars ul li#nav-contact{
  span{
    border-color: #fff;
  }
}
footer{
  background: #111;
  padding-top: 10px;
  color: #999;
  ul{
    display: flex;
    li:hover{
      background: rgba(255, 255, 255, .1);
    }
    li+li{
      border-left: 1px solid #666;
    }
    @include mq('min', 'xl') {
      flex-direction: row;
      justify-content:space-around;
      li{
        text-align: center;
        width: 14.2%;
        a{
          padding: 1rem 0;
          display: block;
          text-align: center;
        }
      }
    }
    @include mq('max', 'xl') {
      flex-wrap:wrap;
      flex-direction: row;
      border-top: 1px solid #666;
      li{
        width: 25%;
        text-align: center;
        border-bottom: 1px solid #666;
        font-size: 1.2rem;
        &:nth-child(4n+1){
          border-left: 0;
        }
        a{
          padding:1rem 0;
          display: block;
          &:hover{
            text-decoration: none;
          }
        }
        &.nav-recruit{
          width: 50%;
        }
      }
    }
    @include mq('max', 'ssm') {
      li{
        width: 50%;
        &.nav-recruit{
          width: 100%;
        }
        &:nth-child(4n+3){
          border-left: 0;
        }
      }
    }
    @media (max-width:600px) and (min-width:520px) {
      li{
        font-size: 1rem;
      }
    }
  }
  .sns{
    p{
      font-size: 1.6rem;
      img.nav-line{
        width: 22px;
        vertical-align: baseline;
        opacity: .85;
        &:hover{
          opacity: .8;
        }
      }
      .disabled{
        opacity: .3;
        pointer-events: none;
      }
    }
  }
  .copyright{
    font-size: .75rem;
  }
}
.to-page-top {
  z-index: 110;
  position: fixed;
  right:0;
  bottom: 0;
  width: 60px;
  height: 60px;
  background: $bg-color;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  display: none;
  a {
    text-decoration: none;
    display: block;
    text-align: center;
    color: #fff;
  }
  @include mq('min', 'md') {
    a{
      padding: 8px;
      font-size: 26px;
    }
  }
  @include mq('max', 'md') {
    width: 14vw;
    height: 54px;
    a{
      padding-top: 10px;
      font-size: 20px;
    }
  }
}

.link-border, .link-circle{
  border: 2px solid #fff;
  padding:.3rem 2rem;
  display: inline-block;
  &:hover{
    background: #fff;
    color: #333 !important;
    text-decoration: none;
  }
}
.link-border{
  border-radius: .5rem;
}
.link-circle{
  border-radius: 1.6rem;
  &:hover a{
    color: #333 !important;
    text-decoration: none;
  }
}
.link-arrow {
  position: relative;
  padding-right: 2.6rem;
  i{
    position: absolute;
    right: 10px;
    top:8px;
  }
  // &::after{
  //   content:'\f054';
  //   font-family: "fontawesome";
  //   position: absolute;
  //   right: 10px;
  //   top:8px;
  //   font-size: .8rem;
  //   color: #f9f9f9;
  // }
  // &:hover{
  //   &::after{
  //     color: #444;
  //   }
  // }

}

.contents{
  color: #dbdbdb;
  min-height:600px;
}

h1 {
  font-weight: 100;
}

.center-center {
  height:240px;
  background: #eee;
  background-size: cover;
  a {
    display: block;

  }

   h3{
    @include centering-elements;
  }
}

.my-card {
  position: relative;
  margin-bottom: 6rem;

  .my-display {
    font-size: 1.8rem;
    margin-top: -7rem;
    margin-left: 1rem;
    position: relative;
    color: #fff;
    font-weight: 100;


  }
  h2 {
    display: inline;
    position: absolute;
    right: 0;
    margin-top: -2rem;
    letter-spacing: 0.2em;
    font-weight: normal;
  }
  &:hover a {
    text-decoration: none !important;
  }


}

.top{
  .contents{
    .feature{
      ul.slick-dots li{
        width: 12px;
        button{
          width: 12px;
          padding:5px 0;
          &::before{
            color: #fff;
          }
        }
      }
    }
    .link-circle{
      min-width:18rem;
      font-size: .95rem;
      font-weight: bold;
    }
    .block{
        background: rgba(0,0,0,.5);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40vw;
        transition: all 0.2s ease-in-out;
        @include mq('max', 'md') {
          height: 60vw;
        }
        &:hover{
          background: rgba(0,0,0,.3);
          color: #fff;
          .link-circle{
            background: #fff;
            color: #333 !important;
            text-decoration: none;
          }
        }
      h2{
        font-size: 3.6rem !important;
        font-weight: bold;
        font-family: "Gothic MB101 Ultra", sans-serif;
      }
    }
    #news{
      h2{
        font-size: 1.2rem;
        font-weight: bold;
      }
      li{
        margin-bottom: .5rem;
        line-height: 1.4;
        span{
          font-size: .85rem;
          display: block;
          color: #999;
        }
      }
      .link-border{
        position: relative;
        color: #999;
        border-color: #999;
        padding:.3rem 4rem;
        svg{
          position: absolute;
          right:10px;
          top:10px;
          font-size: .8rem;
        }
        &:hover{
          border-color: #fff;
        }
      }
    }
  }
  #menu{
    background: url('/uploads/home-content-1.jpg') no-repeat center 0 / cover;
    cursor: pointer;
    .block{
      background: rgba(0,0,0,.4);
      &:hover{
        background: rgba(0,0,0,.2);
      }
    }
  }
  #shop{
    background: url('/uploads/home-content-2.jpg') no-repeat center 0 / cover;
    cursor: pointer;
  }
  #recruit{
    background: url('/uploads/home-content-3.jpg') no-repeat center 0 / cover;
    cursor: pointer;
  }
}

// pages
.contents{
  h2{
    font-size: 1.3rem;
    font-weight: bold;
    &.text-heading{
      font-family: "Gothic MB101 Ultra", sans-serif;
      font-size: 1.6rem;
    }
  }
}
.headline{
  h1{
    color: #eee;
    font-family: "Gothic MB101 Ultra", sans-serif;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 2.8rem;
    a{
      text-decoration: none;
      color: #eee;
    }
  }
}

body.menu{
  .contents{
    .localnav{
      a{
        text-decoration: underline;
        margin:0 .4rem;
      }
      &+p{
        font-size: .9rem;
      }
    }
    #ramen{
      .link-circle{
        background: #D63B3B;
        border-color: #D63B3B;
        strong{
          font-feature-settings : "palt";
        }
        em{
          font-weight: bold;
          font-style: normal;
        }
        a{
          color: #fff !important;
          display: inline-block;
        }
        &:hover{
          transform: scale(1.1);
        }
      }
    }
    .menu-block{
      position: relative;
      img{
        border-radius: 8px;
      }
      h2{
        font-size: 1.4rem;
        span{
          font-size: 1rem;
        }
        span.tax-ex{
          font-size: 1.4rem;
        }
        &+p{
          line-height: 1.8;
        }
      }
      h3{
        font-size: 1.2rem;
      }
      h4{
        font-size: 1.2rem;
      }
      p.price{
        color: #F1D475;
        font-size: 1.1rem;
        font-weight: bold;
        span{
          font-size: .85rem;
        }
        span.tax-ex{
          font-size: 1.1rem;
        }
      }
    }
    .toping{
      background: #D63B3B;
      width:127px;
      height: 127px;
      border-radius: 50%;
      // text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(-10deg);
      position: absolute;
      top:-20px;
      left: -20px;
      a{
        color: #fff;
        font-weight: bold;
        padding-left: .3rem;
        text-decoration: none;
      }
      &:hover{
        transform: scale(1.1) rotate(-9deg);
      }
    }
    #sidemenu{
      h3{
        font-family: "Gothic MB101 Ultra", sans-serif;
      }
    }
    #toping{
      .toping-name{
        font-weight: bold;
      }
      .price{
        color: #F1D475;
      }
    }
    #set{
      .price{
        color: #F1D475;
      }
      .set{
        border-top: 1px solid #fff;
        h3,p{
          margin-bottom: 0;
        }
        @include mq('min', 'md'){
          display: flex;
          justify-content: space-between;
          align-items: center;
          p{
            img{
              width: 100px;
            }
          }
          h3 + p{
            width: 75%;
          }
        }
        @include mq('max', 'sm'){
          p{
            font-feature-settings : "palt";
          }
        }
      }
      h3{
        font-weight: bold;
      }
    }
    #kodawari{
      color: #fff;
      text-shadow: 1px 1px 3px rgba(0,0,0,.6), 2px 0 6px rgba(0,0,0,.3);
      h2, h3{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .sono{
        background:url('/_assets/img/classy_fabric.png');
      }
      .sono1{
        background: url('/uploads/menu-kodawari-1.jpg') no-repeat center center / cover;
      }
      .sono3{
        background: url('/uploads/menu-kodawari-3.jpg') no-repeat center center / cover;
      }
      .sono5{
        background: url('/uploads/menu-kodawari-5.jpg') no-repeat center center / cover;
      }
      .sono6{
        background: url('/uploads/menu-kodawari-6.jpg') no-repeat center center / cover;
      }
      .sono-cover{
        height: 100%;
        width: 100%;
        background: rgba(0,0,0,.5);
      }
      h3 .badge{
        background: #fff;
        color: #000;
        font-size: 2rem;
        box-shadow:0 0 0 1px #fff,0 0 0 1px #000 inset;
      }
      .sono .badge{
        margin-top: -120px;
      }
      .sono{
        h2{
          @include mq('min', 'ssm'){
            padding-right: 70px;
            &.text-heading{
              padding-right: 0;
            }
          }
          @include mq('max', 'ssm'){
            flex-direction:column;
            .badge{
              margin-bottom: 10px;
              margin-top: -90px !important
            }
          }
        }
      }
    }
    .badge{
      background: #D63B3B;
      width:80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(-5deg);
      margin-right: 1rem;
      font-size: 1.2rem;
      text-shadow: none !important;
    }
  }
}
body.menu-preference{
  .headline{
    h1{
      img{
        @include mq('max', 'ssm'){
          width: 300px;
        }
      }
    }
  }
  #noodles, #oil, #soup{
    background: #fff;
    border-bottom: 1px solid #ccc;
    &>.row{
      align-items: center;
      @include mq('max', 'sm') {
        &>.col-12{
          &>h2{
            img{
              max-width: 30%;
            }
          }
        }
      }
    }
  }

  .preference-block{
    // background: #fff;
    color: #000;
    // border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    font-weight: bold;

    h2{
      font-weight: bold;
      margin-bottom: 0;
      @include mq('min', 'md') {
        font-size: 2rem;
      }
    }
  }

  #magokoro{
    ul li{
      font-size: 1.1rem;
      line-height: 1.2;
      margin-bottom: 0.6rem;
    }
  }
}

body.locations{
  .contents{
    h2{
      line-height: 1.6;
      font-weight: bold;
      span{
        display: block;
      }
    }
  }
}
body.locations-top{
  .contents{
    background: #e3e3e3;
    color: #222;
    h2{
      border-bottom: 1px solid #999;
      margin: 0;
      font-size: 1.1rem;
      span{
        line-height: 1.2;
      }
      @include mq('max', 'md') {
        cursor: pointer;
        padding: 1.2rem .8rem .4rem !important;
        span{
          padding-top: .4rem;
          padding-bottom: .1rem;
        }
        &:hover{
          background: #eee;
          span{
            padding-top: .5rem;
            padding-bottom: 0;
          }
        }
      }
      @include mq('min', 'md') {
        .p-arrow{
          display: none;
        }
      }
    }
    @include mq('max', 'md'){
      .container-fluid, .col-12{
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .row{
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    @include mq('min', 'md') {
      #fukui, #ishikawa , #toyama{
        margin:0 30px;
        h2{
          margin:0 -15px;
        }
      }
    }
    .location-group{
      // border-top: 1px solid #999;
      @include mq('max', 'md') {
        display: none;
      }
      @include mq('min', 'md'){
        // margin-right: 15px;
        // margin-left: 15px;
        // display: flex !important;
        // flex-wrap: wrap;
      }
      & > .row{
        background: rgba(0,0,0,.05);
        @include mq('min', 'md'){
          border-bottom: 1px solid #999;
        }
      }
      .col-12{
        border-bottom: 1px solid #999;
        &:hover{
          background: #eee;
          cursor: pointer;
        }
        display:flex;
        @include mq('max', 'md'){
          align-items: center;
          i,svg{
            position: absolute;
            right: 13px;
          }
          &:hover{
            i,svg{
              right: 15px;
            }
          }
        }
        @include mq('min', 'md'){
          i,svg{
            display: none;
          }
        }
      }
      img{
        width:120px;height:120px;
        object-fit: cover;
        margin:15px 0;
        display: block;
        @include mq('max', 'md'){
          margin-left: 15px;
        }
        @include mq('min-max', 'md', 'lg'){
          width:90px;height:90px;
        }
        @include mq('min', 'md'){
          margin-right: 15px;
        }
      }
      dl{
        margin-bottom: 0;
        cursor: pointer;
        @include mq('max', 'md'){
          margin-left: 15px;
        }
        @include mq('min', 'md'){
          .shop-detail{
            display: none;
          }
        }
        dt{
          font-size: 1.2rem;
          padding:.6rem 0 .2rem;
          a{
            color: #333;
            text-decoration: none;
          }
        }
        dd{
          font-size: .9rem;
          padding:0 0 .7rem;
          margin-bottom: 0;
        }
      }
    }
  }
}
body.locations-detail{
  .contents{
    h2{
      background: #e3e3e3;
      color: #222;
      position: relative;
      font-size: 1.4rem;
      span{
        font-size: .8rem;
      }
      .back{
        position: absolute;
        left: 15px;
        top: 25px;
        height: 34px;
        padding:.4rem .6rem .3rem;
        font-size: .8rem;
        color: #777;
        border-color: #888;
        border-width: 1px;
        a{
          color: #777;
          text-decoration: none;
        }
      }
      @include mq('max', 'sm') {
        padding-left: 2rem !important;
      }
    }
    .heading{
      .slider{
        img{
          width: 100%;
        }
      }
      .unslide{
        img{
          width: 100%;
          max-width:640px;
        }
      }
    }
    .shop-info{
      p{
        font-size: .95rem;
        line-height: 1.7;
      }
      .shop-info-option{
        border: solid #ccc;
        border-width: 1px 0;
        padding: .8rem 0;
        margin: .8rem 0 1.8rem;
      }
      dl{
        dt{
          display: inline-block;
          font-weight: normal;
          vertical-align: text-top ;
        }
        dd{
          display: inline-block;
          margin-bottom: 0;
          vertical-align: text-top ;
          p{
            margin-bottom: 0 !important;
          }
        }
      }
    }
    .media{
      display: flex;
      justify-content: space-between;
      a{
        text-align: center;
        padding: .3rem;
        border-radius: 3px;
        background: #fff;
        color: #333;
        width: 32%;
        text-decoration: none;
        border:1px solid #fff;
        &:hover{
          background: #333;
          color: #fff;
        }
        &.link-tabelog{
          background-image: url('/uploads/location-link-tabelog.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 55%;
          img{
            height: 16px;
              opacity: 0;
          }
          &:hover{
            background-image: url('/uploads/location-link-tabelog-o.svg');
          }
        }
        &.disabled{
          opacity: .3;
          line-height: 1.65;
        }
      }
    }
    .map{
      @include mq('min', 'md') {
        max-width: 640px;
        margin: 0 auto;
      }
      #gmap{
        position: relative;
        padding: 0 0 70%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
        iframe{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      p{
        a{
          text-decoration: underline;
        }
      }
    }
  }
}

body.news-list{
  .contents{
    .entry{
      padding-bottom: 1.2rem;
      cursor: pointer;
      border-bottom:1px solid #666;
      margin-bottom: 1.2rem;
      .date{
        font-size: .8rem;
        color: #999;
        margin-bottom: .3rem;
      }
      p{
        margin-bottom: 0;
      }
      // &+.entry{
      //   border-top:1px solid #666;
      //   padding-top: 1.2rem;
      // }
    }
  }
}
body.news-detail{
  .contents{
    .news-meta{
      font-size: .8rem;
      color: #999;
    }
    .entry-body{
      p{
        line-height: 1.9;
        margin-bottom: 1.7rem;
      }
      img{
        max-width: 100% !important;
        height:auto !important;
      }
    }
  }
}

body.about{
  .contents{
    #lead{
      .h2{
        @include mq('max', 'sm') {
          font-size: 1.8rem !important;
        }
      }
      p{
        line-height: 1.8;
      }
    }
    #outline{
      table{
        @include mq('max', 'md') {
          font-size: .9rem;
          th{
            padding-right: 0 !important;
            padding-left: .25rem;
          }
          th+td{
            padding-right: .25rem;
          }
        }
      }
    }
    #idea{
      li{
        line-height: 1.6;
        margin-bottom: .6rem;
        font-size: .95rem;
      }
    }
    #history{
      table{
        th{
          padding-right: 0;
          white-space: nowrap;
          &+td{
            white-space: nowrap;
          }
        }
        td{
          padding-left: .2rem;
        }
        @include mq('max', 'md') {
          font-size: .9rem;
          th{
            padding-left: .25rem;
          }
          th+td{
            text-align: right;
          }
          th+td+td{
            padding-right: .25rem;
          }
        }
      }
    }
  }
}
body.contact{
  .contents{
    .notice{
      border: 1px solid #999;
      h2{
        font-size: 1rem;
      }
      p{
        font-size: .95rem;
      }
      ul{
        padding-left: 1.4rem;
        li{
          font-size: .95rem;
        }
      }
    }
    .form-group{
      dt{
        font-size: .95rem;
      }
      dd{
        font-size: .9rem;
      }
    }
    .btn{
      background:#F1D475;
      font-weight: bold;
      width: 10rem;
      margin-bottom:.5rem;
    }
  }
}
